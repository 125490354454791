import { AuthState, initialAuthState } from './auth.state';
import { AuthActionTypes, AuthActions } from './auth.actions';

export function authReducer(state: AuthState = initialAuthState, action: AuthActions): AuthState {
	switch (action.type) {
		case AuthActionTypes.LoadStorage:
		case AuthActionTypes.Load:
		case AuthActionTypes.Login:
		case AuthActionTypes.Logout:
		case AuthActionTypes.Register:
		case AuthActionTypes.VerifyRegister:
		case AuthActionTypes.Edit:
		case AuthActionTypes.EditAvatar:
		case AuthActionTypes.UpdateCoordinates:
			return {
				...state,
				loading: true
			};
		case AuthActionTypes.LoginOk:
			return {
				...state,
				loading: false,
				access_token: action.response.access_token
			};
		case AuthActionTypes.LoadOk:
			return {
				...state,
				loading: false,
				currentUser: action.response
			};
		case AuthActionTypes.EditOk:
			return {
				...state,
				currentUser: {
					...state.currentUser,
					name: action.request.Name
				}
			};
		case AuthActionTypes.EditAvatarOk:
			return {
				...state,
				currentUser: {
					...state.currentUser,
					profilePhoto: action.request.Image
				}
			};
		case AuthActionTypes.LoginFail:
			return {
				...state,
				loading: false
			};
		case AuthActionTypes.LoadStorageOk:
			if (action.store && action.store.auth) {
				return {
					location: state.location, // Keep current location
					...action.store.auth
				};
			} else {
				return state;
			}
		case AuthActionTypes.UpdateCoordinatesOk:
			return {
				...state,
				location: {
					latitude: action.latitude,
					longitude: action.longitude
				}
			};
		case AuthActionTypes.LoadStorageFail:
		case AuthActionTypes.RegisterFail:
		case AuthActionTypes.RegisterOk:
		case AuthActionTypes.VerifyRegisterOk:
		case AuthActionTypes.VerifyRegisterFail:
		case AuthActionTypes.EditFail:
		case AuthActionTypes.EditAvatarFail:
		case AuthActionTypes.UpdateCoordinatesFail:
			return {
				...state,
				loading: false
			};
		default:
			return state;
	}
}
