import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../shared/states/appstate';
import * as authActions from './auth.actions';
import {
	selectToken,
	selectIsAnonymous,
	selectLoading,
	selectCurrentUser,
	selectLocation,
	selectLockAnonymousFeatures,
	selectError
} from './auth.selectors';
import { LoginRequest, VerifyRegisterRequest, AuthEditRequest, AuthEditAvatarRequest } from '../auth.models';
import { RegisterRequest } from '../../models/profile/registerrequest';
import { Observable } from 'rxjs/internal/Observable';
import { StateError } from '../../shared/state';
import { take, defaultIfEmpty, map, filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AuthFacade {

	public currentUser$ = this.store.pipe(select(selectCurrentUser));
	public isAnonymous$ = this.store.pipe(select(selectIsAnonymous));
	public token$ = this.store.pipe(select(selectToken));
	public loading$ = this.store.pipe(select(selectLoading));
	public location$ = this.store.pipe(select(selectLocation));
	public isLocked$ = this.store.pipe(select(selectLockAnonymousFeatures));
	public readonly error$: Observable<StateError> = this.store.select(selectError);

	public isFirstRun$ = this.token$.pipe(take(1), defaultIfEmpty(null), filter(token => !!!token), map(token => { console.log('token', token, !!!token); return !!!token; }));

	constructor(private store: Store<AppState>) {

	}

	loadCurrentUser() {
		this.store.dispatch(new authActions.LoadAction());
	}

	login(request: LoginRequest) {
		this.store.dispatch(new authActions.LoginAction({ ...request }));
	}

	register(request: RegisterRequest) {
		this.store.dispatch(new authActions.RegisterAction({ ...request }));
	}

	loadStorage() {
		this.store.dispatch(new authActions.LoadStorageAction());
	}

	logout() {
		this.store.dispatch(new authActions.LogoutAction());
	}

	verifyRegister(request: VerifyRegisterRequest) {
		this.store.dispatch(new authActions.VerifyRegisterAction({ ...request }));
	}

	edit(request: AuthEditRequest) {
		this.store.dispatch(new authActions.EditAction({ ...request }));
	}

	editAvatar(request: AuthEditAvatarRequest) {
		this.store.dispatch(new authActions.EditAvatarAction({ ...request }));
	}

	updateCoordinates() {
		this.store.dispatch(new authActions.UpdateCoordinatesAction());
	}
}
