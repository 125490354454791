import { VerifyRegisterRequest, AuthEditRequest, AuthEditAvatarRequest, AuthEditAvatarResponse } from './../auth.models';
import { Action } from '@ngrx/store';
import { User } from '../../models/user';
import { LoginResponse, LoginRequest } from '../auth.models';
import { RegisterRequest } from '../../models/profile/registerrequest';

export enum AuthActionTypes {
	Load = '[Auth] Load',
	LoadOk = '[Auth] Load Ok',
	LoadFail = '[Auth] Load Fail',

	Login = '[Auth] Login',
	LoginOk = '[Auth] Login Ok',
	LoginFail = '[Auth] Login Fail',

	Logout = '[Auth] Logout',

	Register = '[Auth] Register',
	RegisterOk = '[Auth] Register Ok',
	RegisterFail = '[Auth] Register Fail',

	LoadStorage = '[Auth] Load Storage',
	LoadStorageOk = '[Auth] Load Storage Ok',
	LoadStorageFail = '[Auth] Load Storage Fail',

	VerifyRegister = '[Auth] Verify Register',
	VerifyRegisterOk = '[Auth] Verify Register Ok',
	VerifyRegisterFail = '[Auth] Verify Register Fail',

	Edit = '[Auth] Edit',
	EditOk = '[Auth] Edit Ok',
	EditFail = '[Auth] Edit Fail',

	EditAvatar = '[Auth] Edit Avatar',
	EditAvatarOk = '[Auth] Edit Avatar Ok',
	EditAvatarFail = '[Auth] Edit Avatar Fail',

	UpdateCoordinates = '[Auth] Update Coordinates',
	UpdateCoordinatesOk = '[Auth] Update Coordinates Ok',
	UpdateCoordinatesFail = '[Auth] Update Coordinates Fail'
}

export class LoadAction implements Action {
  readonly type = AuthActionTypes.Load;
  constructor() {

  }
}

export class LoadOkAction implements Action {
  readonly type = AuthActionTypes.LoadOk;
  constructor(public response: User) {

  }
}

export class LoadFailAction implements Action {
  readonly type = AuthActionTypes.LoadFail;
  constructor(public error?: any) {

  }
}

export class LoginAction implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public request: LoginRequest) {

  }
}

export class LoginOkAction implements Action {
  readonly type = AuthActionTypes.LoginOk;
  constructor(public response: LoginResponse) {

  }
}

export class LoginFailAction implements Action {
  readonly type = AuthActionTypes.LoginFail;
  constructor(public error?: any) {

  }
}

export class LogoutAction implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class RegisterAction implements Action {
	readonly type = AuthActionTypes.Register;

	constructor(public request: RegisterRequest) {

	}
}

export class RegisterOkAction implements Action {
	readonly type = AuthActionTypes.RegisterOk;
	constructor(public response: any, public registerAction: RegisterAction) {

	}
}

export class RegisterFailAction implements Action {
	readonly type = AuthActionTypes.RegisterFail;
	constructor(public error?: any) {

	}
}

export class LoadStorageAction implements Action {
	readonly type = AuthActionTypes.LoadStorage;
	constructor() {

	}
}

export class LoadStorageOkAction implements Action {
	readonly type = AuthActionTypes.LoadStorageOk;
	constructor(public store: any) {

	}
}

export class LoadStorageFailAction implements Action {
	readonly type = AuthActionTypes.LoadStorageFail;
	constructor(public error?: any) {

	}
}

export class VerifyRegisterAction implements Action {
	readonly type = AuthActionTypes.VerifyRegister;

	constructor(public request: VerifyRegisterRequest) {

	}
}

export class VerifyRegisterOkAction implements Action {
	readonly type = AuthActionTypes.VerifyRegisterOk;
	constructor(public response: any) {

	}
}

export class VerifyRegisterFailAction implements Action {
	readonly type = AuthActionTypes.VerifyRegisterFail;
	constructor(public error?: any) {

	}
}

export class EditAction implements Action {
	readonly type = AuthActionTypes.Edit;

	constructor(public request: AuthEditRequest) {

	}
}

export class EditOkAction implements Action {
	readonly type = AuthActionTypes.EditOk;
	constructor(public request: AuthEditRequest) {

	}
}

export class EditFailAction implements Action {
	readonly type = AuthActionTypes.EditFail;
	constructor(public error?: any) {

	}
}

export class EditAvatarAction implements Action {
	readonly type = AuthActionTypes.EditAvatar;

	constructor(public request: AuthEditAvatarRequest) {

	}
}

export class EditAvatarOkAction implements Action {
	readonly type = AuthActionTypes.EditAvatarOk;
	constructor(public request: AuthEditAvatarResponse) {

	}
}

export class EditAvatarFailAction implements Action {
	readonly type = AuthActionTypes.EditAvatarFail;
	constructor(public error?: any) {

	}
}

export class UpdateCoordinatesAction implements Action {
	readonly type = AuthActionTypes.UpdateCoordinates;

	constructor() {

	}
}

export class UpdateCoordinatesOkAction implements Action {
	readonly type = AuthActionTypes.UpdateCoordinatesOk;
	constructor(public latitude, public longitude) {

	}
}

export class UpdateCoordinatesFailAction implements Action {
	readonly type = AuthActionTypes.UpdateCoordinatesFail;
	constructor(public error?: any) {

	}
}

export type AuthActions =
	LoadAction |
	LoadOkAction |
	LoadFailAction |
	LoginAction |
	LoginOkAction |
	LoginFailAction |
	LogoutAction |
	RegisterAction |
	RegisterOkAction |
	RegisterFailAction |
	LoadStorageAction |
	LoadStorageOkAction |
	LoadStorageFailAction |
	VerifyRegisterAction |
	VerifyRegisterOkAction |
	VerifyRegisterFailAction |
	EditAction |
	EditOkAction |
	EditFailAction |
	EditAvatarAction |
	EditAvatarOkAction |
	EditAvatarFailAction |
	UpdateCoordinatesAction |
	UpdateCoordinatesOkAction |
	UpdateCoordinatesFailAction;
