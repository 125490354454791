import { Injectable } from '@angular/core';

// TODO: Implement rxjs if you want to use this service for darkmode
@Injectable()
export class FeatureSwitchService {

	private featureSwitches = {
		darkMode: false,
	}

	constructor() {
		window['setFeatureSwitch'] = (name, value) => {
			this.set(name, value);
		};
		window['getFeatureSwitch'] = (name) => {
			return this.featureSwitches[name];
		};
	}

	public initialize() {
		for (const key in this.featureSwitches) {
			const valStr = `__PROPOOL_RELEASE_FEATURESWITCH_${key}__`;
			const val = !!valStr;
			if (val !== undefined && valStr.indexOf('__PROPOOL_RELEASE_FEATURESWITCH_') === -1) {
				this.featureSwitches[key] = val;
			}
		}
	}

	public set(name: string, value: boolean) {
		this.featureSwitches[name] = value;
	}

	public isEnabled(name: string) {
		return this.featureSwitches[name];
	}
}
