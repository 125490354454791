import { Injectable } from '@angular/core';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Platform, AlertController } from '@ionic/angular';
import { Observable, of, from } from 'rxjs';
import { Camera } from '@ionic-native/camera/ngx';
import { AlertService } from './alert.service';

@Injectable()
export class DiagnosticService {
	public hub: any;

	constructor(private camera: Camera, private diagnostic: Diagnostic, private platform: Platform, private alertService: AlertService) {
		const successCallback = (isAvailable) => { console.log('Is available? ' + isAvailable); };
		const errorCallback = (e) => console.error(e);

		// this.diagnostic.isCameraAvailable().then(successCallback).catch(errorCallback);

		// this.diagnostic.isBluetoothAvailable().then(successCallback, errorCallback);

		// this.diagnostic.getBluetoothState()
		// .then((state) => {
		// 	if (state == this.diagnostic.bluetoothState.POWERED_ON){
		// 	// do something
		// 	} else {
		// 	// do something else
		// 	}
		// }).catch(e => console.error(e));

	}

	isCameraAvailable(): Observable<any> {
		return of(false);
		// return Observable.fromPromise(this.diagnostic.isCameraAuthorized());
		// if(this.platform.is("cordova")){
		// }
		// return Observable.of(false);

	}

	getBluetoothState(): Observable<any> {
		if (this.platform.is('cordova')) {
			this.diagnostic.getBluetoothState().then(success => {
				return of(success);
			}, error => {
				return of(error);
			});
		}
		return of(false);
	}

	getInternetState(): Observable<any> {
		return null;
	}

	switchToBluetoothSettings(): any {
		this.diagnostic.switchToBluetoothSettings();
	}


	nativeFunctionalityNotAvailable(): any {
		if (this.platform.is('android')) {
			this.alertService.nativeAndroidFunctionNotAvailable();
		} else if  (this.platform.is('ios')) {
			this.alertService.nativeIosFunctionNotAvailable();
		}
	}

}
