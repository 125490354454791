<ion-app>
	<loading *ngIf="(authFacade.loading$ | async) === true"></loading>
	<!-- <h1 *ngIf="authFacade.loading$ | async; else notLoading">Loading...</h1>
	<ng-template #notLoading> -->

	<ion-router-outlet></ion-router-outlet>
	<!-- </ng-template> -->

  <tutorial *ngIf="isFirstRun"></tutorial>

</ion-app>
