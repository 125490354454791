import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Component({
	templateUrl: 'intro.page.html',
	styleUrls: ['intro.page.scss']
})
export class IntroPage {

	constructor(private storage: Storage, private router: Router) {

	}

	// TODO: To NGRX
	async finish() {
		await this.storage.set('introCompleted', true);
		this.router.navigateByUrl('/');
	}
}
