import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AuthActionTypes } from '../../auth/state/auth.actions';

@Injectable()
export class AppEffects {

	constructor(private actions$: Actions, private storage: Storage, private store: Store<any>) {

	}

	set$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AuthActionTypes.LoginOk,
                AuthActionTypes.LoadOk,
                AuthActionTypes.RegisterOk,
                AuthActionTypes.VerifyRegisterOk,
                AuthActionTypes.EditOk,
                AuthActionTypes.EditAvatarOk
            ),
            switchMap(_ => this.store.pipe(take(1))),
            tap((store: any) =>	{
                console.debug('[AppEffects] Syncing local storage with current state', store);
                this.storage.set('store', JSON.stringify(store));
            })
        )
    }, { dispatch: false });
}
