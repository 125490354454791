import { NgModule } from '@angular/core';
import { RelativeDateTimePipe } from './RelativeDateTimePipe';
import { ConsolePipe } from './ConsolePipe';

// https://stackoverflow.com/questions/39007130/the-pipe-could-not-be-found-angular2-custom-pipe
@NgModule({
	declarations: [
		RelativeDateTimePipe,
		ConsolePipe
	],
	imports: [

	],
	exports: [
		RelativeDateTimePipe,
		ConsolePipe
	]
})
export class PipesModule {}
