import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class DarkModeService {

	// https://ionicframework.com/docs/theming/dark-mode
	public determine() {
        switch(environment.darkMode) {
            case 'auto':
                // Use matchMedia to check the user preference
                const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

                this.toggleDarkTheme(prefersDark.matches);

                // Listen for changes to the prefers-color-scheme media query
                prefersDark.addEventListener("change", (mediaQuery: MediaQueryListEvent) => this.toggleDarkTheme(mediaQuery.matches));
                break;
            case 'on':
                this.toggleDarkTheme(true);
                break;
            case 'off':
                this.toggleDarkTheme(false);
                break;
        }
	}

    // Add or remove the "dark" class based on if the media query matches
    private toggleDarkTheme(shouldAdd) {
        // document.documentElement.classList.toggle('dark', shouldAdd);
    }
}
