import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Component({
	selector: 'loading',
	templateUrl: 'loading.component.html',
	styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit, OnDestroy {

	private loading: HTMLIonLoadingElement;

	constructor(public loadingController: LoadingController) {

	}

	async presentLoading() {
		// this.loading = await this.loadingController.create({
		// 	message: 'Laden...'
		// });

		// await this.loading.present();

		// const { role, data } = await this.loading.onDidDismiss();
	}

	ngOnInit() {
		this.presentLoading();
	}

	ngOnDestroy() {
		if (this.loading) {
			this.loading.dismiss();
		}
	}

//   async presentLoadingWithOptions() {
// 	const loading = await this.loadingController.create({
// 		spinner: null,
// 		duration: 5000,
// 		message: 'Please wait...',
// 		translucent: true,
// 		cssClass: 'custom-class custom-loading'
// 	});
// 	return await loading.present();
//   }
}
