import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AppSettingsService } from './appsettings.service';

@Injectable()
export class StorageService {

	private prefix = '';

	constructor(private storage: Storage, private platform: Platform, appsettingsService: AppSettingsService) {
		this.prefix += appsettingsService.Get('currentHost') + '_';
	}

  async create(): Promise<Storage> {
    return await this.storage.create();
  }

	setRefreshToken(refreshToken): Promise<any> {
		return this.platform.ready().then(() => {
			const itemName = 'refresh_token';
			const itemValue = refreshToken;

			return this.storage.set(this.prefix + itemName, itemValue);
		});
	}

	getRefreshToken(): Promise<any> {
		return this.platform.ready().then(() => {

			const itemName = 'refresh_token';

			return new Promise((resolve, reject) => {
        this.storage.get(this.prefix + itemName).then(itemValue => {
          if (itemValue === null) {
            reject('itemValue is null');
          }
          resolve(itemValue);
        });
			});
		});
	}

	removeRefreshToken(): Promise<any> {
		return this.platform.ready().then(() => {
			const itemName = 'refresh_token';

      return this.storage.remove(this.prefix + itemName);
		});
	}

  getStore(): Promise<any> {
    return this.storage.get('store');
  }

  removeStore(): Promise<void> {
    return this.storage.remove('store');
  }
}
