import { Injectable, Injector } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

@Injectable()

export class ScreenOrientationService {

	constructor(private platform: Platform, private screenOrientation: ScreenOrientation) {
	}

	unlock(): void {
		this.platform.ready().then(() => {
			if (this.platform.is('cordova')) {
				// let screenOrientation = this.injector.get("ScreenOrientation");

				this.screenOrientation.unlock();
			}
		});
	}

	setLandscape(): void {
		this.platform.ready().then(() => {
			if (this.platform.is('cordova')) {
				// let screenOrientation = this.injector.get("ScreenOrientation");
				this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
			}
		});
	}

	setPortrait(): void {
		this.platform.ready().then(() => {
			if (this.platform.is('cordova')) {
				// let screenOrientation = this.injector.get("ScreenOrientation");
				this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
			}
		});
	}

	setBoth(): void {
		this.platform.ready().then(() => {
			if (this.platform.is('cordova')) {
				// let screenOrientation = this.injector.get("ScreenOrientation");
				this.screenOrientation.unlock();
			}
		});
	}

	getScreenOrientation(): string {
		return this.screenOrientation.type;
	}
}
