import { StorageService } from './../../shared/services/storage.service';
import { NavController } from '@ionic/angular';
import { UserDialogService } from '../../profile/services/userdialog.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { catchError, switchMap, tap, take, filter } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import { Injectable } from '@angular/core';
import { AuthActionTypes, LoginFailAction, RegisterFailAction } from './auth.actions';
import { AuthHttpService } from '../services/auth.httpservice';
import { Router } from '@angular/router';
import { Geolocation } from '@capacitor/geolocation';

@Injectable()
export class AuthEffects {

	constructor(
		private actions$: Actions,
		private authEndpoint: AuthHttpService,
		private router: Router,
		private storageService: StorageService,
		private userDialogService: UserDialogService,
		private navCtrl: NavController
	) {

	}

	load$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.Load),
            switchMap((_: AuthActions.LoadAction) => this.authEndpoint.getCurrentUser().pipe(
                switchMap(res => of(new AuthActions.LoadOkAction(res as any))),
                catchError(res => of(new AuthActions.LoadFailAction(res))))
            )
        )
    });

	// @Effect({ dispatch: false })
	// loadFail$ = this.actions$.pipe(
	// 	ofType(ProfileActionTypes.LoadFail),
	// 	tap((_: ProfileActions.LoadFailAction) => {
	// 		console.error(`FOUT: Laden van profiel mislukt.`);
	// 	})
	// );

	login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.Login),
            switchMap((action: AuthActions.LoginAction) =>
                this.authEndpoint.login(action.request).pipe(
                    switchMap(res => of(new AuthActions.LoginOkAction(res as any))),
                    catchError(res => of(new AuthActions.LoginFailAction(res)))
                )
            )
	    );
    });

	loginOk$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.LoginOk),
            switchMap((_: AuthActions.LoginOkAction) => {
                return of(new AuthActions.LoadAction());
            }),
            tap(() => { this.router.navigateByUrl('/tabs/profile'); })
        )
    });

	loginFail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.LoginFail),
            tap((action: LoginFailAction) => {
                if (action.error.invalid_login) {
                    this.userDialogService.wrongCredentialsAlert();
                } else if (action.error.confirm_email) {
                    this.userDialogService.notActivatedAlert();
                    this.router.navigateByUrl('/tabs/profile/verify-register');
                } else if (action.error.account_locked) {
                    this.userDialogService.emptyCredentialsAlert();
                }
            })
        )
    }, { dispatch: false });

	logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.Logout),
            switchMap(() => from(this.storageService.removeStore())),
            switchMap(() => of(new AuthActions.LoginAction({ IsAnonymous: true })))
        )
    });

	register$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.Register),
            switchMap((action: AuthActions.RegisterAction) => {
                return this.authEndpoint.register(action.request).pipe(
                    switchMap(res => of(new AuthActions.RegisterOkAction(res as any, action))),
                    catchError(res => of(new AuthActions.RegisterFailAction(res)))
                );
            })
        )
    });

	registerOk$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.RegisterOk),
            tap((action: AuthActions.RegisterOkAction) => {
                this.navCtrl.navigateRoot('/tabs/profile').then(_ => {
                    this.navCtrl.navigateForward('/tabs/profile/verify-register');
                });
                this.userDialogService.emailSentAlert(action.registerAction.request.Email);
            }),
            switchMap(_ => of(new AuthActions.LoadAction()))
        )
    });

	registerFail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.RegisterFail),
            tap((action: RegisterFailAction) => {
                if (action.error.email_taken) {
                    this.userDialogService.emailTakenAlert();
                } else if(action.error.passwords_not_equal) {
                    this.userDialogService.passwordsNotEqualAlert();
                }
                console.error(`FOUT: Register failed.`);
            })
        )
    } , { dispatch: false });

	// TODO: Move LoadStorage to App NGRX
	loadStorage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.LoadStorage),
            switchMap((_: AuthActions.LoadStorageAction) => {
                return from(this.storageService.getStore()).pipe(
                    take(1),
                    switchMap(result => of(new AuthActions.LoadStorageOkAction(JSON.parse(result)))),
                    catchError(result => of(new AuthActions.LoadStorageFailAction(result)))
                );
            })
        )
    });

	loadStorageOk$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.LoadStorageOk),
            filter((action: AuthActions.LoadStorageOkAction) => !(action.store && action.store.auth && action.store.auth.access_token)),
            switchMap((_: AuthActions.LoadStorageOkAction) => {
                return of(new AuthActions.LoginAction({ IsAnonymous: true}));
            }),
        )
    });

	loadStorageOk2$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.LoadStorageOk),
            filter((action: AuthActions.LoadStorageOkAction) => action.store && action.store.auth && action.store.auth.access_token),
            switchMap((_: AuthActions.LoadStorageOkAction) => {
                return of(new AuthActions.LoadAction());
            }),
        )
    });

	loadStorageFail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.LoadStorageFail),
            tap(() => {
                console.error(`FOUT: Store laden mislukt.`);
            })
        );
     }, { dispatch: false });

	verifyRegister$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.VerifyRegister),
            switchMap((action: AuthActions.VerifyRegisterAction) => {
                return this.authEndpoint.verifyRegister(action.request).pipe(
                    switchMap(res => of(new AuthActions.VerifyRegisterOkAction(res as any))),
                    catchError(res => of(new AuthActions.VerifyRegisterFailAction(res.error)))
                );
            })
        )
    });

	verifyRegisterOk$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.VerifyRegisterOk),
            switchMap((_: AuthActions.VerifyRegisterOkAction) => {
                return of(new AuthActions.LoadAction());
            }),
            tap(() => {
                this.userDialogService.verifyRegisterSuccess();
            })
        )
    });

	verifyRegisterFail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.VerifyRegisterFail),
            tap((action: AuthActions.VerifyRegisterFailAction) => {
                if (action.error.invalid_email) {
                    this.userDialogService.globalErrorAlert();
                } else if (action.error.invalid_pincode) {
                    this.userDialogService.wrongCodeAlert();
                }
            })
        );
    }, { dispatch: false });

	edit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.Edit),
            switchMap((action: AuthActions.EditAction) => {
                return this.authEndpoint.edit(action.request).pipe(
                    switchMap(_ => of(new AuthActions.EditOkAction(action.request))),
                    catchError(err => of(new AuthActions.EditFailAction(err)))
                );
            })
        )
    });

	editOk$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.EditOk),
            tap(() => {
                this.navCtrl.navigateBack('/tabs/profile');
            })
        );
    }, { dispatch: false });

	editFail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.EditFail),
            tap(() => {
                console.error(`FOUT: Edit failed.`);
            })
        );
    }, { dispatch: false });

	editAvatar$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.EditAvatar),
            switchMap((action: AuthActions.EditAvatarAction) => {
                return this.authEndpoint.editAvatar(action.request).pipe(
                    switchMap(_ => of(new AuthActions.EditAvatarOkAction(action.request))),
                    catchError(err => of(new AuthActions.EditAvatarFailAction(err)))
                );
            })
        )
    });

	editAvatarOk$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.EditAvatarOk),
            tap(() => { this.navCtrl.navigateBack('/tabs/profile'); })
        );
    }, { dispatch: false });

	editAvatarFail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.EditAvatarFail),
            tap(() => {
                console.error(`FOUT: Edit avatar failed.`);
            })
        );
    }, { dispatch: false });

	updateCoordinates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.UpdateCoordinates),
            switchMap((_: AuthActions.UpdateCoordinatesAction) => {
                const geolocationOptions = { timeout: 10000, enableHighAccuracy: true }; // TODO: Move Geolocation to it's own service
                return from(Geolocation.getCurrentPosition(geolocationOptions))
                    .pipe(
                        switchMap(resp => of(new AuthActions.UpdateCoordinatesOkAction(resp.coords.latitude, resp.coords.longitude))),
                        catchError(err => of(new AuthActions.UpdateCoordinatesFailAction(err)))
                    );
            })
        )
    });

	// @Effect({ dispatch: false})
	// updateCoordinatesOk$ = this.actions$.pipe(
	// 	ofType(AuthActionTypes.UpdateCoordinatesOk),
	// 	tap(() => { console.debug("Set coordinates OK!"); })
	// );

	updateCoordinatesFail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.UpdateCoordinatesFail),
            tap(error => {
                console.error(`FOUT: Update coordinates failed.`, error);
            })
        );
    } , { dispatch: false });
}
