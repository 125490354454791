import { NgLetDirective } from './ng-let.directive';
import { InfiniteScrollLoadingDirective } from './InfiniteScrollLoadingDirective';
import { NgModule } from '@angular/core';
import { ScrollHideDirective } from './ScrollHideDirective';

@NgModule({
	declarations: [
		InfiniteScrollLoadingDirective,
		// HideHeaderDirective,
		ScrollHideDirective,
		NgLetDirective,
	],
	exports: [
		InfiniteScrollLoadingDirective,
		// HideHeaderDirective,
		ScrollHideDirective,
		NgLetDirective,
	]
})
export class DirectivesModule {}
