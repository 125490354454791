import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { OneSignal } from '@ionic-native/onesignal/ngx';

@Injectable()

export class PushService {

	private currentIds: {
		userId: string;
		pushToken: string;
	};

	constructor(private oneSignal: OneSignal, private platform: Platform) {

	}

	register(toastCtrl: ToastController): Promise<{}> {
		if (this.platform.is('cordova')) {
			return new Promise((resolve, reject) => {

				this.oneSignal.startInit('456c2125-1824-48c7-82e3-72a296472a06', '377337889917');
				this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
				this.oneSignal.iOSSettings({ kOSSettingsKeyAutoPrompt: false, kOSSettingsKeyInAppLaunchURL: false});
				this.oneSignal.promptForPushNotificationsWithUserResponse().then(success => {
					console.log('Enabled push notifications');
				}).then(error => {
					console.log('Disabled push notifications');
				});
				this.oneSignal.promptLocation();
				this.oneSignal.handleNotificationReceived().subscribe((notification) => {
					// Do something when notification is received
					toastCtrl.create({
						'message': notification.payload.body,
						duration: 3000,
						position: 'top'
					}).then(toast => toast.present());
				});
				this.oneSignal.handleNotificationOpened().subscribe((notification) => {
					// Do something when notification is opened
				});
				this.oneSignal.endInit();

				this.oneSignal.getIds().then(ids => {
					console.debug('[Push] data retrieved', ids);
					this.currentIds = ids;
					resolve(ids);
				}).catch(error => {
					console.debug('[Push][Error] not retrieved', error);
					reject(error);
				});
			});
		} else {
			return new Promise((resolve, _) => {
				resolve({});
			});
		}
	}
}
