import { AuthFacade } from './../../auth/state/auth.facade';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class LoadedGuard implements CanActivate {

	constructor(private authFacade: AuthFacade) {

	}

	canActivate(_1: ActivatedRouteSnapshot, _2: RouterStateSnapshot): Observable<boolean> {
		return this.authFacade.loading$.pipe(
			skipWhile(loading => loading),
			map(loading => !loading)
		);
	}
}
