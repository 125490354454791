import { AlertService } from './../../shared/services/alert.service';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable()
export class UserDialogService extends AlertService {

	constructor(alertCtrl: AlertController, navCtrl: NavController, modalCtrl: ModalController) {
		super(alertCtrl, navCtrl, modalCtrl);
	}

	emptyPasswordAlert() {
		this.alertCtrl.create({
			header: 'Oeps!',
			message: 'Je hebt geen wachtwoord opgegeven.',
			buttons: ['Opnieuw proberen']
		}).then(alert => alert.present());
	}

	notActivatedAlert(): void {
		this.alertCtrl.create({
			header: 'Oeps!',
			message: 'Je hebt je account nog niet geactiveerd.',
			buttons: [
				{
					text: 'Nu activeren',
					role: 'cancel',
					handler: () => {
						this.navCtrl.navigateForward('verifyregister-page');
					}
				}
			]
		}).then(alert => alert.present());
	}

	emptyCredentialsAlert(): void {
		this.alertCtrl.create({
			header: 'Oeps!',
			message: 'Je hebt geen e-mailadres en/of wachtwoord opgegeven.',
			buttons: ['Opnieuw proberen']
		}).then(alert => alert.present());
	}

	wrongCredentialsAlert(): void {
		this.alertCtrl.create({
			header: 'Oeps!',
			message: 'Het opgegeven e-mailadres en/of wachtwoord is onjuist.',
			buttons: ['Opnieuw proberen']
		}).then(alert => alert.present());
	}

	emptyCodeAlert() {
		this.alertCtrl.create({
				header: 'Oeps!',
				message: 'Je hebt geen verificatiecode opgegeven.',
				buttons: ['Opnieuw proberen']
		}).then(alert => alert.present());
	}

	wrongCodeAlert() {
		this.alertCtrl.create({
				header: 'Oeps!',
				message: 'De verificatiecode is onjuist.',
				buttons: ['Opnieuw proberen']
		}).then(alert => alert.present());
	}

	verifyRegisterSuccess(): void {
		this.alertCtrl.create({
			header: 'Gelukt!',
			message: 'Je account is geverifieerd.',
			buttons: [
				{
					text: 'OK',
					handler: () => {
						this.navCtrl.navigateRoot('/tabs/profile');
					}
				}
			]
		}).then(alert => alert.present());
	}

	passwordsNotEqualAlert() {
		this.alertCtrl.create({
			header: 'Oeps!',
			message: 'De opgegeven wachtwoorden komen niet overeen.',
			buttons: ['Opnieuw proberen']
		}).then(alert => alert.present());
	}

	shortPasswordAlert() {
		this.alertCtrl.create({
			header: 'Oeps!',
			message: 'Het wachtwoord moet uit minimaal 6 karakters bestaan.',
			buttons: ['Opnieuw proberen']
		}).then(alert => alert.present());
	}

	// nameTakenAlert(): void {
	// 	this.alertCtrl.create({
	// 		header: 'Oeps!',
	// 		message: 'De opgegeven naam is al in gebruik.',
	// 		buttons: ['Opnieuw proberen']
	// 	}).then(alert => alert.present());
	// }

	emailTakenAlert(): void {
		this.alertCtrl.create({
			header: 'Oeps!',
			message: 'Het opgegeven e-mailadres is al in gebruik.',
			buttons: ['Opnieuw proberen']
		}).then(alert => alert.present());
	}

	emailSentAlert(email): void {
		this.alertCtrl.create({
			header: 'Bevestiging verstuurd!',
			message: 'Er is een e-mail gestuurd naar ' + email + ' met daarin de verificatiecode.',
			buttons: ['Ok']
		}).then(alert => alert.present());
	}
}
