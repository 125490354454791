import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppSettingsService {

	public constructor() {

	}

	public GetConfig(): object {
		return environment;
	}

	public Get(settingName: string): any {
		const config = this.GetConfig();
		return config[settingName];
	}

	public Set(settingName: string, settingValue: any): void {
		const config = this.GetConfig();
		config[settingName] = settingValue;
	}

	public GetHost(): string {
		const currentHost = this.Get('currentHost');
		return currentHost;
	}
	
	public getApiHost(): string {
		const apiHost = this.Get('apiHost');
		return apiHost;
	}

	public UseMocking(): boolean {
		return environment.useMocking;
	}
}
