import { SharedModule } from './../../shared.module';
import { LoadingComponent } from './loading.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [
		LoadingComponent,
	],
	imports: [
		SharedModule,
		FormsModule
	],
	exports: [
		LoadingComponent
	]
})
export class LoadingComponentModule {
	constructor() {

	}
}
