import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroPageModule } from './shared/pages/intro/intro.page.module';
import { IntroPage } from './shared/pages/intro/intro.page';
import { IntroGuard } from './shared/guards/intro.guard';
import { LoadedGuard } from './shared/guards/loaded.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsModule),
		canActivate: [IntroGuard, LoadedGuard]
	},
	{
		path: 'intro',
		component: IntroPage
	}
];
@NgModule({
	imports: [
		IntroPageModule,
		RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            relativeLinkResolution: 'legacy' // TODO: Still needed?
        })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
