import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourceDeleteResponse, ListRequestOptions, ResourceLoadResponse, ResourceGetResponse, ResourceSaveResponse } from './state';
import { environment } from '../../environments/environment';

export abstract class BaseHttpService {
	protected readonly abstract endpoint: string;

	constructor(public http: HttpClient) {

	}

	protected apiUrl(...urlParts: string[]) {
		const parts = [
			...urlParts.concat.call([], ...urlParts.map(p => p.split('/')).filter(Boolean))
		]
		.map(part => part.trim())
		.filter(Boolean)
		.join('/')
		.replace(/\/+/g, '/');

		return environment.apiHost + parts;
	}

	protected castObject<T>(type: (new () => T), input: any): T {
		const newObj: T = Object.assign(new type(), input) as T;
		return newObj;
	}
}

export abstract class BaseEntityHttpService<R> extends BaseHttpService {
	protected readonly abstract endpoint: string;

	constructor(public http: HttpClient) {
		super(http);
	}

	load(query?: ListRequestOptions): Observable<ResourceLoadResponse<R>> {
		throw new Error('Method not implemented.');
	}

	get(id: any): Observable<ResourceGetResponse<R>> {
		throw new Error('Method not implemented.');
	}

	save(item: R): Observable<ResourceSaveResponse> {
		throw new Error('Method not implemented.');
	}

	delete(id: any): Observable<ResourceDeleteResponse> {
		throw new Error('Method not implemented.');
	}
}
