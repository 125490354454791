import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { WalkthroughComponent, WalkthroughContainerComponent, WalkthroughFlowComponent, WalkthroughNavigate } from 'angular-walkthrough';
import { timer } from 'rxjs';

@Component({
	selector: 'tutorial',
	templateUrl: 'tutorial.component.html',
	styleUrls: [ 'tutorial.component.scss']
})

export class TutorialComponent implements AfterViewInit {

	@Input() pre = true;

	@ViewChild('preWalkthrough') preWalkthrough: WalkthroughFlowComponent;
	@ViewChild('walkthrough') walkthrough: WalkthroughFlowComponent;

	ngAfterViewInit() {
		if (this.pre) {
			this.startPreWalkthrough();
		} else {
			this.startWalkthrough();
		}
	}

	public startPreWalkthrough() {
		timer(500).subscribe(_ => {
			this.preWalkthrough.start();

			if (!WalkthroughComponent.walkthroughHasShow()) {
				this.startPreWalkthrough();
			} else {
        WalkthroughComponent.onNavigateNext.subscribe((compt: WalkthroughNavigate) => {
          switch (compt.previous.id) {
            case 'pre-walkthrough-profile-1':
              this.clickHamburger();
              break;
          }
        });
      }
		});
	}

	public startWalkthrough() {
		timer(500).subscribe(_ => {
			this.walkthrough.start();

			if (!WalkthroughComponent.walkthroughHasShow()) {
				this.startWalkthrough();
			} else {
        WalkthroughComponent.onNavigateNext.subscribe((compt: WalkthroughNavigate) => {
          // switch (compt.previous.id) {
          //   case 'pre-walkthrough-profile-1':
          //     this.clickHamburger();
          //     break;
          // }
        });
      }
		});
	}

	public focusClickAction(_: Event, contenaire: WalkthroughContainerComponent) {
		contenaire.next();
	}

	/**
	 * Pre-walkthrough
	 */

	public clickHamburger(): void {
		this.clickElement('ion-toolbar ion-button');
	}

	/**
	 * Walkthrough
	 */

	private clickElement(selector: string): void {
			(document.querySelector(selector) as HTMLElement).click();
	}
}
