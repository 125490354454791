import { AuthFacade } from '../../auth/state/auth.facade';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, switchMap, defaultIfEmpty } from 'rxjs/operators';

@Injectable()
export class LocationInterceptor implements HttpInterceptor {

	constructor(private authFacade: AuthFacade) {

	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.authFacade.location$.pipe(take(1), defaultIfEmpty(null), switchMap(location => {
			if (location?.latitude && location?.longitude) {
				const newRequest = req.clone({
					headers: req.headers
						.set('Latitude', location.latitude.toString())
						.set('Longitude', location.longitude.toString())
				});
				return next.handle(newRequest);
			} else {
				return next.handle(req);
			}
		}));
	}
}
