<ion-slides pager="true">
    <ion-slide class="step-one">
        <img src="/assets/images/intro/slide1.png" />
    </ion-slide>

    <ion-slide class="step-two">
        <img src="/assets/images/intro/slide2.png" />
    </ion-slide>

    <ion-slide class="step-three">
        <img src="/assets/images/intro/slide3.png" />
        <ion-button (click)="finish()">Ik snap het</ion-button>
    </ion-slide>
</ion-slides>