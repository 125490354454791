import { PlayerUtilitiesService } from './../../players/services/player-utilities.service';
import { VerifyRegisterRequest, AuthEditResponse, AuthEditRequest, AuthEditAvatarResponse, AuthEditAvatarRequest } from '../auth.models';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { User } from '../../models/user';
import { Observable } from 'rxjs';
import { Token } from '../../models/token';
import { BaseHttpService } from '../../shared/base.httpservice';
import { HttpClient } from '@angular/common/http';
import { LoginRequest } from '../auth.models';
import { RegisterRequest } from '../../models/profile/registerrequest';

@Injectable()
export class AuthHttpService extends BaseHttpService {
	protected readonly endpoint = this.apiUrl('auth');

	constructor(public http: HttpClient, private playerUtilitiesService: PlayerUtilitiesService) {
		super(http);
	}

	getCurrentUser(): Observable<User> {
		return this.http.get<User>(`${this.endpoint}/user`).pipe(
			switchMap(res => this.playerUtilitiesService.mapAsObservable(res))
		);
	}

	login(body: LoginRequest): Observable<Token> {
		return this.http.post<Token>(`${this.endpoint}/login`, body);
	}

	logout(): Observable<any> {
		return this.http.post<any>(`${this.endpoint}/logout`, null);
	}

	register(request: RegisterRequest): Observable<any> {
		return this.http.post<any>(`${this.endpoint}/register`, request);
	}

	verifyRegister(request: VerifyRegisterRequest): Observable<any> {
		return this.http.post<any>(`${this.endpoint}/verifyregister`, request);
	}

	edit(request: AuthEditRequest): Observable<AuthEditResponse> {
		return this.http.post<AuthEditResponse>(`${this.endpoint}/edit`, request);
	}

	editAvatar(request: AuthEditAvatarRequest): Observable<AuthEditAvatarResponse> {
		return this.http.post<AuthEditAvatarResponse>(`${this.endpoint}/editavatar`, request);
	}
}
