import { PlayerUtilitiesService } from './../players/services/player-utilities.service';
import { UserDialogService } from '../profile/services/userdialog.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './state/auth.reducer';
import { AuthEffects } from './state/auth.effects';
import { AuthHttpService } from './services/auth.httpservice';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { LocationInterceptor } from '../shared/interceptors/location.interceptor';
import { environment } from 'environments/environment';
import { AuthHttpMockService } from './services/auth.httpmockservice';

@NgModule({
	imports: [
		IonicModule,
		EffectsModule.forFeature([AuthEffects]),
		StoreModule.forFeature('auth', authReducer)
	],
	providers: [
		UserDialogService,
		AuthHttpMockService,
		PlayerUtilitiesService,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LocationInterceptor, multi: true },
		{ provide: AuthHttpService, useClass: environment.useMocking ? AuthHttpMockService : AuthHttpService }
	]
})
export class AuthModule {
	constructor() {

	}
}
