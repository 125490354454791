<ng-walkthrough-flow
*ngIf="pre"
#preWalkthrough
id="pre-walkthrough-flow"
focusBackdrop="true"
focusHighlightAnimation="true"
closeButton="true"
closeAnywhere="false"
arrowColor="#FFF"
showArrow="true"
radius="auto"
[texts]="{ previous: 'Vorige', next: 'Volgende', close: 'Sluiten'}">

<ng-walkthrough
  id="pre-walkthrough-profile-1"
  contentText="Op de profielpagina kan je een account maken, je account beheren en andere account-gebonden acties bekijken en uitvoeren."
  verticalAlignContent="above"
  focusElementSelector="#tab-button-profile">
</ng-walkthrough>

<ng-walkthrough
  id="pre-walkthrough-profile-2"
  contentText="In het submenu op de profielpagina vind je ons privacystatement en kun je feedback achterlaten."
  focusElementSelector="ion-toolbar ion-button">
</ng-walkthrough>

<ng-walkthrough
  id="pre-walkthrough-profile-3"
  contentText="Dit was het voor nu. Wil je de tutorial van de gehele applicatie zien, kies dan 'Tutorial'."
  focusElementSelector="#submenu-tutorial">
</ng-walkthrough>

</ng-walkthrough-flow>

<ng-walkthrough-flow
*ngIf="!pre"
#walkthrough
id="walkthrough-flow"
focusBackdrop="true"
focusHighlightAnimation="true"
closeButton="true"
closeAnywhere="false"
arrowColor="#FFF"
showArrow="true"
radius="auto"
[texts]="{ previous: 'Vorige', next: 'Volgende', close: 'Sluiten'}">

<ng-walkthrough
  id="walkthrough-profile-1"
  contentText="1"
  verticalAlignContent="above"
  focusElementSelector="#tab-button-profile">
</ng-walkthrough>

<ng-walkthrough
  id="walkthrough-profile-2"
  contentText="2"
  focusElementSelector="ion-toolbar ion-button">
</ng-walkthrough>

<ng-walkthrough
  id="walkthrough-profile-3"
  contentText="3"
  focusElementSelector="#submenu-tutorial">
</ng-walkthrough>

</ng-walkthrough-flow>
