import { Environment } from "./environment.type";

export const environment: Environment = {
	production: true,
	currentHost: '__PROPOOL_RELEASE_HOST__',
	apiHost : '__PROPOOL_RELEASE_API_HOST__',
	login_path: 'profile/login',
	client_id: 'propoolapp',
	useMocking: false,
	lockAnonymousFeatures: true,
    darkMode: 'auto',
	options: {
		pageSize: 10
	}
};
