<ion-card>
	<ion-card-header>
		
		<ion-card-title>
			<ion-label>
				<ion-icon name="warning" class="title-icon"></ion-icon>
				<span class="title-text">Fout!</span>
			</ion-label>
		</ion-card-title>
		<ion-card-subtitle>Er is een fout opgetreden</ion-card-subtitle>
	</ion-card-header>
	
	<ion-card-content>
		<p *ngIf="error == StateError.Unknown">
			Er is een onbekende fout opgetreden
		</p>
		<p *ngIf="error == StateError.ServerError">
			Er is een fout opgetreden, de beheerders zijn op de hoogte gesteld.
		</p>
		<p *ngIf="error == StateError.NoInternetAccess">
			Er kon geen verbinding worden gemaakt met de ProPool server. Controleer je internetverbinding.
		</p>

		<br />

		<ion-button color="light" fill="outline" (click)="restart()">App herstarten</ion-button>
	</ion-card-content>
</ion-card>