import { BreakType } from './../../models/breaktype';
import { Injectable } from '@angular/core';
import { AppSettingsService } from './appsettings.service';
import { MatchStatus } from '../../models/matchstatus';
import { SignalRHubService } from 'signalr-utilities';

export class ScoreUpdate {
	currentUserId: string;
	matchId: string;
	scoreChallenger: number;
	scoreChallenged: number;
}

export class StatusUpdate {
	matchId: string;
	status: MatchStatus;
}

export class PlayerUpdate {
	matchId: string;
	userId: string;
	isChallenger: boolean;
}

export class ResetUpdate {
	matchId: string;
}

export class RoundUpdate {
	roundId: string;
	breakType: BreakType;
	race: number;
}

@Injectable()
export class SignalRService extends SignalRHubService {
	public getNewScore$ = super.register<ScoreUpdate>('getNewScore', 'currentUserId', 'matchId', 'scoreChallenger', 'scoreChallenged'); //combineLatest([this.authFacade.currentUser$, super.register<ScoreUpdate>('getNewScore')]).pipe(filter(([user, update]) => user.id !== update.currentUserId), map(([_, update]) => update));
	public getNewStatus$ = super.register<StatusUpdate>('getNewStatus', 'matchId', 'status');
	public getNewPlayer$ = super.register<PlayerUpdate>('getNewPlayer', 'matchId', 'userId', 'isChallenger');
	public getNewReset$ = super.register<ResetUpdate>('getNewReset', 'matchId');
	public getNewRound$ = super.register<RoundUpdate>('getNewRound', 'roundId', 'breakType', 'race');

	constructor(public appSettings: AppSettingsService) {
    	super();
	}

	public prepare(): void {
		const url = `${this.appSettings.getApiHost()}signalr`;
    	super.prepare(url);
	}

	public joinMatchRoom(matchId: string): Promise<void> {
		return super.send("JoinMatchRoom", matchId);
	}

	public joinRoundRoom(roundId: string): Promise<void> {
		return super.send("joinRoundRoom", roundId);
	}
}
