import { SharedModule } from './../../shared.module';
import { NgModule } from '@angular/core';
import { IntroPage } from './intro.page';
import { IonicStorageModule } from '@ionic/storage-angular';

@NgModule({
	declarations: [
		IntroPage,
	],
	imports: [
		SharedModule,
		IonicStorageModule,
	],
	exports: [
		IntroPage
	]
})
export class IntroPageModule {

}
