import { StateError } from "./../state";
import { AlertService } from "../services/alert.service";
import { catchError } from "rxjs/operators";
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpEvent,
    HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private alertService: AlertService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                // TODO: Maybe login  with wrong credentials should be 404 and not bad request?
                let stateError = StateError.Unknown;

                if (error.status || error.status === 0) {
                    if (error.status.toString()[0] === "0") {
                        stateError = StateError.NoInternetAccess;
                        this.alertService.showConnectionError();
                    }
                    if (
                        error.status.toString()[0] === "4" &&
                        error.status.toString() !== "400"
                    ) {
                        stateError = StateError.ServerError;
                        // TODO: Show 'something went wrong, error has been sent' error box
                        this.alertService.showConnectionError();
                    }
                    if (error.status.toString()[0] === "5") {
                        stateError = StateError.ServerError;
                        // TODO: Send to AppInsights via some LogService + // TODO: Show 'something went wrong, error has been sent' error box
                        this.alertService.showConnectionError();
                    }
                }

                return throwError({
                    ...error,
                    stateError,
                });
            })
        );
    }
}
