import { Component, Input } from '@angular/core';
import { StateError } from '../../state';

@Component({
	selector: 'error',
	templateUrl: 'error.component.html',
	styleUrls: ['error.component.scss']
})
export class ErrorComponent {
	public StateError = StateError;

	@Input() error: StateError;
	
	constructor() {

	}

	public restart() {
		window.location.reload();
	}
}
