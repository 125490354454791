import { StateError } from './../../shared/state';
import { User } from '../../models/user';

export class AuthState {
	currentUser: User;
	access_token: string;
	loading: boolean;
	location: {
		latitude: null,
		longitude: null
	};
	error: StateError;
}

export const initialAuthState: AuthState = {
	currentUser: null,
	access_token: null,
	loading: true,
	location: {
		latitude: null,
		longitude: null
	},
	error: StateError.None,
};
