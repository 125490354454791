import { StorageService } from './shared/services/storage.service';
import { PWAUpdateService } from './shared/services/PWAUpdateService';
import { FeatureSwitchService } from './shared/services/FeatureSwitch.service';
import { DarkModeService } from './shared/services/DarkMode.service';
import { Component, OnInit } from '@angular/core';
import { Platform, ToastController, AlertController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { ScreenOrientationService } from './shared/services/ScreenOrientationService';
import { PushService } from './shared/services/PushService';
import { AuthFacade } from './auth/state/auth.facade';
import { take } from 'rxjs/operators';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html'
})
export class ProPoolApp implements OnInit {

    public isFirstRun = false;

    constructor(
        public authFacade: AuthFacade,
        private screenOrientationService: ScreenOrientationService,
        private alertCtrl: AlertController,
        private toastCtrl: ToastController,
        private pushService: PushService,
        private platform: Platform,
        private statusBar: StatusBar,
        private splashScreen: SplashScreen,
        private darkModeService: DarkModeService,
        private featureSwitchService: FeatureSwitchService,
        private storageService: StorageService,
        private pwaUpdateService: PWAUpdateService) {
        this.initializeApp();
    }

    async ngOnInit() {
        await this.storageService.create();
    }

	initializeApp() {
		this.platform.ready().then(() => {
			this.retrieveStore();
			console.debug('[Platforms]', this.platform.platforms());

			if (this.platform.is('cordova')) {
				this.setScreenOrientation();
				this.setStatusBar();
			}

			this.pwaUpdateService.checkForUpdates();

            // TODO: [Violation] Only request geolocation information in response to a user gesture.
			this.registerPushAndGeo();

			if (!this.featureSwitchService.isEnabled('DARKMODE')) { // todo: fix
				this.darkModeService.determine();
			}

			if (this.platform.is('cordova')) {
				this.hideSplashScreen();
			}

            // this.authFacade.isFirstRun$.subscribe(isFirstRun => {
            //   console.log('ProPoolApp.isFirstRun', isFirstRun);
            //   this.isFirstRun = isFirstRun;
            // });
		});
	}

	private setScreenOrientation() {
		this.screenOrientationService.unlock();
		this.screenOrientationService.setPortrait();
	}

	private setStatusBar() {
		this.statusBar.overlaysWebView(true);
		this.statusBar.backgroundColorByHexString('#000');
		this.statusBar.styleLightContent();
	}

	private retrieveStore() {
		this.authFacade.loadStorage();
	}

	registerPushAndGeo(): void {
		this.pushService.register(this.toastCtrl).then((ids) => {
			this.authFacade.updateCoordinates();
		});
	}

	loginWithToken(): void {
		this.authFacade.currentUser$.pipe(take(1)).subscribe(currentUser => {
			if (currentUser == null) {
				this.authFacade.login({ IsAnonymous: true });
				console.debug('current User was null');
			} else {
				console.debug('current user was set (via store?)');
			}
		});
	}

	hideSplashScreen(): void {
		setTimeout(() => {
			this.splashScreen.hide();
		}, 100);
	}

	userOnlyAlert(): void {
		this.alertCtrl.create({
			header: 'Welkom bij Pro Pool',
			message: 'Begin door een speler uit te dagen of een nieuwe wedstrijd aan te maken.' ,
			buttons: [
				// {
				// 	text: 'Inloggen',
				// 	handler: () => {
				// 		this.nav.navigateForward('login-page', {
				// 		});
				// 	}
				// },
				// {
				// 	text: 'Registeren',
				// 	handler: () => {
				// 		this.nav.navigateForward('register-page', {
				// 		});
				// 	}
				// },
				{
					text: 'OK',
					role: 'cancel'
				},
			]
		}).then(alert => alert.present());
	}

	loginErrorAlert(): void {
		this.alertCtrl.create({
			header: 'Oeps!',
			subHeader: 'Er is iets misgegaan met inloggen.',
			buttons: ['Opnieuw proberen']
		}).then(alert => alert.present());
	}
}
