import { SortType } from '../models/sorttype';

export interface ResourceLoadResponse<T = any> {
	total: number;
	results: T[];
}

export interface ResourceGetResponse<T = any> {
	result: T;
}

export interface ResourceSaveResponse {
	AllErrors: any[];
	FieldErrors: any;
	GlobalErrors: any[];
	Message: string;
	Successful: boolean;
}

export interface ResourceDeleteResponse {
	AllErrors: any[];
	FieldErrors: any;
	GlobalErrors: any[];
	Message: string;
	Successful: boolean;
}

export interface ResourcePagination {
	Page?: number;
	PageSize?: number;
	Total?: number;
}

export interface ResourceSort {
	name: string;
	ascending: boolean;
}

export interface ResourceFilterItem {
	name: string;
	value: any;
}

export interface ListRequestOptions {
	Page?: number;
	PageSize?: number;
	Filter?: string;
	Sort?: number;
	SortType?: SortType;
}

export enum StateError {
	None,
	Unknown,
	NoInternetAccess,
	ServerError,
}

export interface ResourceState<R = any> {
	Items: R[];
	Loading: boolean;
	Query: ListRequestOptions;
	Total: number;
	Error: StateError;
}
