import { ErrorComponent } from './../components/error/error.component';
import { Injectable } from '@angular/core';
import { AlertController, NavController, ModalController } from '@ionic/angular';
import { Observable, from } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { StateError } from '../state';

@Injectable()
export class AlertService {

	public constructor(protected alertCtrl: AlertController, protected navCtrl: NavController, private modalCtrl: ModalController) {

	}

	protected showAsObservable(input: Promise<HTMLIonAlertElement>) {
		return from(input).pipe(flatMap(x => from(x.present())));
	}

	protected show(input: Promise<HTMLIonAlertElement>) {
		const sub = from(input).subscribe(alert => {
			alert.present();
			sub.unsubscribe();
		});
	}

	usersOnlyAlert(functionalityName: string) {
		const alert = this.alertCtrl.create({
			header: 'Oeps!',
			message: functionalityName + ' is alleen beschikbaar voor ingelogde gebruikers.',
			buttons: [
				{
					text: 'Inloggen',
					handler: () => {
						this.navCtrl.navigateRoot('/tabs/profile/login');
					}
				},
				{
					text: 'Registreren',
					handler: () => {
						this.navCtrl.navigateRoot('/tabs/profile/register');
					}
				},
				{
					text: 'Annuleren',
					role: 'cancel',
					handler: () => {
					}
				}
			]
		});
		this.show(alert);
	}

	nativeAndroidFunctionNotAvailable(): Observable<void> {
		const alert = this.alertCtrl.create({
			message: 'Oeps!',
			subHeader: 'Deze functionaliteit is niet beschikbaar. Download de app vanuit de App Store om deze functionaliteit te gebruiken.',
			buttons: [
				{
					text: 'OK',
					role: 'cancel',
					handler: () => {}
				},
				{
					text: 'Naar App Store',
					handler: () => {
						window.location.href = 'https://play.google.com/store/apps/details?id=com.propool.Propoolapp';
					}
				}
			]
		});
		return this.showAsObservable(alert);
	}

	nativeIosFunctionNotAvailable(): Observable<void> {
		const alert = this.alertCtrl.create({
			message: 'Oeps!',
			subHeader: 'Deze functionaliteit is niet beschikbaar. Download de app vanuit de App Store om deze functionaliteit te gebruiken.',
			buttons: [
				{
					text: 'OK',
					role: 'cancel',
					handler: () => {
					}
				},
				{
					text: 'Naar App Store',
					handler: () => {
						window.location.href = 'https://itunes.apple.com/nl/app/pro-pool/id1271217711?mt=8';
					}
				}
			]
		});
		return this.showAsObservable(alert);
	}

	showConnectionError(): void {
		// const alert = this.alertCtrl.create({
		// 	header: 'Error!',
		// 	subHeader: 'Er kon geen verbinding gemaakt worden met de ProPool server. Controleer je internetverbinding',
		// 	buttons: ['OK']
		// });
		// return this.show(alert);
		this.modalCtrl.create({
			component: ErrorComponent,
			cssClass: 'error-backdrop',
			showBackdrop: true,
			backdropDismiss: true,
			componentProps: {
				error: StateError.NoInternetAccess
			}
		}).then(modal => modal.present());
	}

	globalErrorAlert() {
		const alert = this.alertCtrl.create({
			header: 'Oeps!',
			message: 'Er is iets fout gegaan.',
			buttons: ['Opnieuw proberen']
		});
		this.show(alert);
	}

	emptyNameAlert() {
		const alert = this.alertCtrl.create({
			header: 'Oeps!',
			message: 'Je hebt geen naam opgegeven.',
			buttons: ['Opnieuw proberen']
		})
		this.show(alert);
	}

	emptyDescriptionAlert() {
		const alert = this.alertCtrl.create({
			message: 'Oeps!',
			subHeader: 'Je hebt geen beschrijving opgegeven.',
			buttons: ['Opnieuw proberen']
		});
		this.show(alert);
	}

	emptyEmailAlert() {
		const alert = this.alertCtrl.create({
			header: 'Oeps!',
			message: 'Je hebt geen e-mailadres opgegeven.',
			buttons: ['Opnieuw proberen']
		});
		this.show(alert);
	}

	wrongEmailAlert() {
		const alert = this.alertCtrl.create({
			header: 'Oeps!',
			message: 'Je hebt een onjuist e-mailadres opgegeven.',
			buttons: ['Opnieuw proberen']
		});
		this.show(alert);
	}
}
