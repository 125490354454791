import { ResourceLoadResponse } from './../../shared/state';
import { User } from './../../models/user';
import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';

@Injectable()
export class PlayerUtilitiesService {

	public mapArrayAsObservable(users: User[]): Observable<User[]> {
		return of(users).pipe(
			mergeMap(data =>
				from(data).pipe(
					mergeMap(
						item => this.mapAsObservable(item)
					),
					toArray(),
					map(arr => ([...arr]))
				),
			),
		);
	}

	public mapResourceAsObservable(users: ResourceLoadResponse<User>): Observable<ResourceLoadResponse<User>> {
		return of(users).pipe(
			mergeMap(data =>
				from(data.results).pipe(
					mergeMap(
						item => this.mapAsObservable(item)
					),
					toArray(),
					map(arr => ({
						total: users.total,
						results: [...arr]
					} as ResourceLoadResponse<User>))
				),
			),
		);
	}

	public mapAsObservable(user: User, isTournamentContext = false): Observable<User> {
		if (!user) {
			return (isTournamentContext) ? of(null) : of(this.mapNoUser());
		}

		if (!user.profilePhoto) {
			user.profilePhoto = (user.roles?.indexOf('AnonymousUser') !== -1)
				? './assets/images/defaultavataranonymous.png'
				: './assets/images/defaultavatar.png';
		}

		return of(user);
	}

	private mapNoUser(): User {
		return {
			name: 'Gastspeler',
			profilePhoto: './assets/images/defaultavataranonymous.png'
		} as User;
	}
}
