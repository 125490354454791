import { ErrorComponentModule } from './components/error/error.component.module';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from './pipes/PipesModule';
import { DirectivesModule } from './directives/DirectivesModule';

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		PipesModule,
		DirectivesModule,
		ErrorComponentModule
	],
	exports: [
		CommonModule,
		IonicModule,
		PipesModule,
		DirectivesModule,
		ErrorComponentModule
	]
})
export class SharedModule {}
