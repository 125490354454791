import { WalkthroughModule } from 'angular-walkthrough';
import { SharedModule } from '../../shared.module';
import { NgModule } from '@angular/core';
import { TutorialComponent } from './tutorial.component';

@NgModule({
	declarations: [
		TutorialComponent,
	],
	imports: [
		SharedModule,
    WalkthroughModule
	],
	exports: [
		TutorialComponent
	]
})
export class TutorialComponentModule {
	constructor() {

	}
}
