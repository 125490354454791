import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';
import { environment } from '../../../environments/environment';

export const select: MemoizedSelector<object, AuthState> = createFeatureSelector<AuthState>('auth');
export const selectCurrentUser = createSelector(select, state => state.currentUser);
export const selectIsAnonymous = createSelector(select, state =>
	state.currentUser ? state.currentUser.roles.indexOf('AnonymousUser') !== -1 : false
);
export const selectToken = createSelector(select, state => state.access_token);
export const selectLoading = createSelector(select, state => state.loading);
export const selectLocation = createSelector(select, state => state.location);
export const selectLockAnonymousFeatures = createSelector(selectIsAnonymous, isAnonymous =>
	isAnonymous && environment.lockAnonymousFeatures);

export const selectError = createSelector(select, state => state.error);

/*
	TODO:
		- Default state says currentUser is null but I guess there should be something like a 'loading' which will be set to true when
		the current user is retrieved. Even if it is Anonymous or not
*/
