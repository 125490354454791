import { Storage } from '@ionic/storage';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class IntroGuard implements CanActivate {
	constructor(private storage: Storage, private router: Router) {

	}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return true;
		// TODO: Make intro workable
		const isComplete = await this.storage.get('introCompleted');

		if (!isComplete) {
			this.router.navigateByUrl('/intro');
		}

		return isComplete;
	}
}
