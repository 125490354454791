import { AuthFacade } from '../../auth/state/auth.facade';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, switchMap, defaultIfEmpty } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(private authFacade: AuthFacade) {

	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.authFacade.token$.pipe(take(1), defaultIfEmpty(null), switchMap(token => {
			if (token) {
				const newRequest = req.clone({
					headers: req.headers.set('Authorization', `Bearer ${token}`)
				});
				return next.handle(newRequest);
			} else {
				return next.handle(req);
			}
		}));
	}
}
