import { VerifyRegisterRequest, AuthEditResponse, AuthEditRequest, AuthEditAvatarResponse, AuthEditAvatarRequest } from '../auth.models';
import { Injectable } from '@angular/core';
import { User } from '../../models/user';
import { Observable, of } from 'rxjs';
import { Token } from '../../models/token';
import { LoginRequest } from '../auth.models';
import { RegisterRequest } from '../../models/profile/registerrequest';
import { UserStatistics } from 'app/models/userstatistics';

@Injectable()
export class AuthHttpMockService {

	getCurrentUser(): Observable<User> {
		return of(this.getUser());
	}

	login(body: LoginRequest): Observable<Token> {
		return of({
			access_token: '1234-1234'
		} as Token);
	}

	logout(): Observable<any> {
		return of();
	}

	register(request: RegisterRequest): Observable<any> {
		return of();
	}

	verifyRegister(request: VerifyRegisterRequest): Observable<any> {
		return of();
	}

	edit(request: AuthEditRequest): Observable<AuthEditResponse> {
		return of();
	}

	editAvatar(request: AuthEditAvatarRequest): Observable<AuthEditAvatarResponse> {
		return of();
	}

	getUser() {
		return {
			id: 1337,
			name: 'Mock User',
			amountOfCheckins: 4,
			deviceId: '1234-1234',
			distance: 0,
			email: 'mock@mailinator.com',
			emailConfirmed: true,
			experience: 325,
			language: 'nl',
			maxExperience: 600,
			pincode: 1234,
			rank: 'Mock Rank',
			roles: [
				'User'
			],
			timezone: 'nl',
			username: 'mock@mailinator.com',
			statistics: {
				Official: {
					Played: 60,
					Won: 30,
					Winpercentage: 50,
					Winstreak: 8
				}
			} as unknown as UserStatistics
		} as unknown as User;
	}
}
