import { ToastController } from '@ionic/angular';
import { Injectable, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs/operators';

// https://stackoverflow.com/questions/50968902/angular-service-worker-swupdate-available-not-triggered
@Injectable()
export class PWAUpdateService implements OnDestroy {

  private swUpdateSubscription: Subscription;

  constructor(private swUpdate: SwUpdate, private toastCtrl: ToastController) {
  }

  	public checkForUpdates(): void {
		this.swUpdateSubscription = this.swUpdate.versionUpdates.pipe(filter(evt => evt.type === 'VERSION_READY')).subscribe(_ => this.promptUser());

		if (this.swUpdate.isEnabled) {
		console.debug('[ServiceWorker] Update is enabled');
		// Required to enable updates on Windows and ios.
		this.swUpdate.activateUpdate();

		this.swUpdate.checkForUpdate().then(() => {
			console.debug('[ServiceWorker] Checking for updates');
		});

		interval(60 * 60 * 1000).subscribe(() => {
			this.swUpdate.checkForUpdate().then(() => {
			console.debug('[ServiceWorker] Checking for updates');
			});
		});
		} else {
		console.debug('[ServiceWorker] Update is NOT enabled');
		}

		// Important: on Safari (ios) Heroku doesn't auto redirect links to their https which allows the installation of the pwa like usual
		// but it deactivates the swUpdate. So make sure to open your pwa on safari like so: https://example.com then (install/add to home)
  	}

	promptUser(): void {
		console.debug('[ServiceWorker] Update available, showing user prompt');
		this.swUpdate.activateUpdate().then(() => {
			this.toastCtrl.create({
				message: 'Er is een nieuwe update van de app geinstalleerd!',
				position: 'top',
				buttons: [
					{
						side: 'start',
						icon: 'star',
						text: 'Opnieuw laden',
						handler: () => {
							window.location.reload();
						}
					},
				]
			}).then(toast => {
				toast.onDidDismiss().then(() => {
					console.log('[ServiceWorker] toast onDismiss');
				});
				toast.present();
			}).catch(error => {
				console.debug('[ServiceWorker] Error when activating update', error);
			});
		});
  }

	ngOnDestroy(): void {
		if (this.swUpdateSubscription) {
			this.swUpdateSubscription.unsubscribe();
		}
	}
}
