import { TutorialComponentModule } from './shared/components/tutorial/tutorial.component.module';
import { PWAUpdateService } from './shared/services/PWAUpdateService';
import { FeatureSwitchService } from './shared/services/FeatureSwitch.service';
import { AuthModule } from './auth/auth.module';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Camera} from '@ionic-native/camera/ngx';
import { ProPoolApp } from './app.component';
import { AuthFacade } from './auth/state/auth.facade';
import { StorageService } from './shared/services/storage.service';
import { SignalRService } from './shared/services/signalR.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { PushService } from './shared/services/PushService';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { ScreenOrientationService } from './shared/services/ScreenOrientationService';
import { DiagnosticService } from './shared/services/DiagnosticService';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppSettingsService } from './shared/services/appsettings.service';
import { AppRoutingModule } from './app-routing.module';
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy, IonicModule } from '@ionic/angular';
import { InputValidator } from './shared/helpers/inputvalidator';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AlertService } from './shared/services/alert.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AppEffects } from './shared/states/app.effects';
import { LoadingComponentModule } from './shared/components/loading/loading.component.module';
import { HttpErrorInterceptor } from './shared/interceptors/httperror.interceptor';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { DarkModeService } from './shared/services/DarkMode.service';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

registerLocaleData(localeNl);

@NgModule({
  declarations: [
    ProPoolApp
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    LoadingComponentModule,
    IonicModule.forRoot({
      mode: 'ios',
      backButtonText: 'Terug'
    }),
    IonicStorageModule.forRoot({
      name: '__propool'
    }),
    AuthModule,
    StoreModule.forRoot({}, {
        runtimeChecks: {
            // TODO: Fix
			strictStateImmutability: false,
			strictActionImmutability: false,
			strictStateSerializability: false,
			strictActionSerializability: false
        }
    }),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
    TutorialComponentModule
  ],
  providers: [
    OneSignal,
    Diagnostic,
    PushService,
    StorageService,
    StatusBar,
    SplashScreen,
    ScreenOrientation,
    ScreenOrientationService,
    Camera,
    AuthFacade,
    Geolocation,
    SignalRService,
    DiagnosticService,
    AppSettingsService,
    InputValidator,
    AlertService,
    DarkModeService,
    FeatureSwitchService,
    SwUpdate,
    PWAUpdateService,
    { provide: LOCALE_ID, useValue: "nl-NL" },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [ProPoolApp]
})
export class AppModule {

}
